import React from 'react'
import PropTypes from 'prop-types'
import {NewLaneSection} from '../styles/Base'
import {AddLaneLink} from '../styles/Elements'

const NewLaneSectionComponent = ({t, onClick}) => (
  <NewLaneSection>
    <AddLaneLink t={t} onClick={onClick}>{t('Add another lane')}</AddLaneLink>
  </NewLaneSection>
)

NewLaneSectionComponent.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default NewLaneSectionComponent
