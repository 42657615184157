import React, { useEffect, useState } from "react";
// import Board from "react-trello";
import Board from '../../component/customKanbanReactTrello/index'
import ClickableRowData from "../../component/rowData/clickableRowData"
import { customUseSelector } from "../../store/customUseSelector";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { updateCells } from "../../store/table/tableThunk";
import { binarySearch } from "../../utility/storageHelpers";
import { updateKanban } from "../../api/filterApi";
import { updateFilterView } from "../../store/allTable/allTableSlice";
import { Typography } from "@mui/material";
import { getAllTableInfo } from "../../store/allTable/allTableSelector";
import { getKanbanHeaderData, updateUrlParam} from "../../table/utils";
import RowPopup from "../../component/table/rowPopup/rowPopup";

export default function KanbanView() {
  const dispatch = useDispatch();
  const rows = customUseSelector((state) => state.table.data);
  const { tableName, filterName, dbId } = useParams();
  const AllTableInfo = customUseSelector((state) => getAllTableInfo(state));
  const filterInfo = AllTableInfo.tables[tableName]?.filters?.[filterName];
  const tableData = customUseSelector((state) => state.tables.tables[tableName])
  const [rowPopupData, setRowPopupData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const filterData = tableData.filters[filterName]
  const  stackBy = filterData.view.stackBy;
  const cardHeight = 125;  // if change also change item height in Lane.js
  
  const  heading = filterData.view?.heading||'autonumber';
  const columns = customUseSelector((state) => state.table.columns);
  const [activeRowId, setActiveRowId] = useState(null);
  const filterFields = columns
  .filter((column) => column.metadata?.hide !== true) 
  .map((column) => column.id);
  const stackByField = customUseSelector(
      (state) => state.tables.tables[tableName].fields[stackBy]
    ) || [];
  const isUser = stackByField.fieldType === 'user';
  const orgUsers= customUseSelector((state) => state.dataBase.currentOrgUsers);
  const userAcess = Object.keys(customUseSelector((state)=>state.tables.userAcess) || {})
  const options = isUser ?  userAcess: filterData.view.stackOrder || [];

  const currentField = filterInfo.currentField;
  const order = filterInfo.order;
  
  const querystring = new URLSearchParams(window.location.search);
  const rowId = querystring.get('row');
  const uniqueLaneId = "##!~1741666537385~`*&%_mm4m5y4k>#!@@";//laneid for undefined fields in kanban

  useEffect(()=>{
    if(rowId) setActiveRowId(rowId);
  },[rowId])

  // useEffect(()=>{
  //   if(rows.length == 0 || isPopupOpen ) return ;
  //   if(rowId){
  //     const index = searchIndex(rows,rowId,(data,autonumber)=> data.autonumber - autonumber);
  //     if(index == -1) return;
  //     setRowPopupData(rows[index]);
  //     setIsPopupOpen(true);
  //   }
  // },[rowId, rows])
  function onClose(){
    updateUrlParam('row')
    setIsPopupOpen(false);
  }
  const lanes = options.reduce((obj, key) => {
    obj[key] = [];
    return obj;
  }, {});

  for (let row of rows) {
    let filteredRow = filterFields.reduce((newRow, field)=>{
      newRow[field] = row[field];
      return newRow;
    }, {});
    let id ;
    if(isUser && row[stackBy] && row[stackBy].length>0){
      if(row[stackBy][0]!=null)
      id=row[stackBy][0]?.id || row[stackBy];
    }else{
      id=(!row[stackBy]) ? uniqueLaneId : row[stackBy];
    }
    const headingValue = getKanbanHeaderData(tableData.fields[heading].fieldType,row[heading]);
    if(!lanes[id])
    {
      id=uniqueLaneId
      if(!lanes[id])
      lanes[id]=[]
    }
    lanes[id].push({
        heading: headingValue.length > 20 ? headingValue.slice(0, 20) + "..." : headingValue,
        value: filteredRow,
        id : row.autonumber,
        sort: row[currentField],
        laneId : row[stackBy] ?? uniqueLaneId,
        sx : {
          transform: "scale(0.85)",
          maxWidth: 280,
          border: 'none',
          backgroundColor: '#fff',
          boxShadow: 'rgba(0, 0, 0, 0.20) 2.4px 2.4px 3.2px',
          height : `${cardHeight}px`,
          maxHeight : `${cardHeight}px`
        },
        activeRowId, 
        setActiveRowId, 
        setRowPopupData,
        setIsPopupOpen,
      });
    }

  function laneSortFunction(card1, card2) {
    
    const value1 = card1.sort;
    const value2 = card2.sort;
    if (value1 == null || value2 == null) return 0;
    let comparison = 0;
    if (typeof value1 === "number" && typeof value2 === "number") {
      comparison = value1 - value2;
    } else {
      comparison = String(value1).localeCompare(String(value2), undefined, { numeric: true, sensitivity: 'base' });
    }
    return order === 'asc' ? comparison : -comparison;
  }

  function onCardMoveAcrossLanes (prevLane, newLane, autonumber){
    if(prevLane == newLane) return;
    dispatch(updateCells({
      updatedArray : [{
        where : 'autonumber='+autonumber,
        fields : {
          [stackBy] : (newLane == uniqueLaneId) ? null : (isUser ? [newLane] : newLane)
        }
      }], 
      indexIdMapping: {[autonumber]: binarySearch(rows, autonumber, (a, b)=> a.autonumber - b)}
    }))
  }

  async function handleLaneDragEnd(prevLane, newLane){
    if(isUser) return;
    const updatedView = await updateKanban(dbId, tableName, filterName, {prevLane, newLane});
    const view = updatedView.data.data.view;
    dispatch(updateFilterView({tableName, filterName, view}));
  }
  if(!options.length){
    return (
      <Typography align='center' variant = 'h5'>Add some options to {stackByField.fieldName} for this view.</Typography>
    )
  }
  return (
    <>
   {!isPopupOpen && <Board
      components = {{
        Card : ClickableRowData
      }}
      data={{
        lanes: Object.entries(lanes).map(([laneId, cards]) => {
          const laneName = laneId === uniqueLaneId ? (isUser ? "No User Found" : "No options found") : (isUser ? orgUsers[laneId]?.name : laneId);
          return {
            cards,
            title: laneName,
            label:(cards?.length || 0),
            id: laneId,
            style: {
              width: laneId === uniqueLaneId ? 260 : 290,
              backgroundColor: laneId === uniqueLaneId ? ' #F5F5F5' : '#e9ecef',
              height: '100%'
            }
          }}),
      }}
      draggable
      collapsibleLanes
      laneSortFunction={laneSortFunction}
      onCardMoveAcrossLanes={onCardMoveAcrossLanes}
      handleLaneDragEnd={handleLaneDragEnd}
      // onCardClick={()=>{}}
      style={{
        backgroundColor: "#fff",
        // height: '74vh'
        height: '100%'
      }}
      cardHeight={cardHeight}
    />}
    {isPopupOpen && (<RowPopup rowData={rowPopupData} closePopup={onClose}/>)}
</>
  );
}
