import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Tooltip, Typography } from "@mui/material";
import './rowData.scss'


function RowData({ heading, value, sx }) {
  return (
    <Box className="row-parent" sx = {sx}>
      <Typography className="row-heading">{heading}</Typography>
      <Box className="row">
        {Object.entries(value).map(([key, value], idx) => {
          const dataToShow = typeof value === 'object' || typeof value === 'boolean'  ? JSON.stringify(value) : value;
          return (
            <Box key={idx} className = "row-fields">
              <Typography className = "row-field">{key}</Typography>
              <Tooltip title = {dataToShow}><Typography className = "row-data">{dataToShow}</Typography></Tooltip>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
RowData.propTypes = {
  row: PropTypes.bool,
  heading: PropTypes.string,
  value : PropTypes.any,
  sx : PropTypes.object
};
export default RowData;
