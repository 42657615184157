import React from 'react'
import PropTypes from 'prop-types'
import {AddCardLink} from '../styles/Base'

const AddCardLinkComponent = ({onClick, t}) => (
  <AddCardLink onClick={onClick}>{t('Click to add card')}</AddCardLink>
)

AddCardLinkComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default AddCardLinkComponent
