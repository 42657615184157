import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button, MenuItem, Select, CircularProgress, LinearProgress } from "@mui/material";
import ReactPlayer from "react-player";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";

const ffmpeg = new FFmpeg();

const ImagePreviewAndCompress = ({ imageSrc, videoSrc, mediaSize, isUploading, uploadImage }) => {
  const [compressedVideo, setCompressedVideo] = useState(null);
  const [compressedSize, setCompressedSize] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [quality, setQuality] = useState("high");
  const getBlobObject = async (blobUrl) => {
    try {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        
        const randomNumber = Math.floor(Math.random() * 10000);
        const filename = `${randomNumber}.mp4`;
        const file = new File([blob], filename, { type: "video/mp4" });

        return { blob, file };
    } catch (error) {
        console.error("Error fetching blob:", error);
        return null;
    }
};
const getVideoDuration = (file) =>
  new Promise((resolve, reject) => {
    try {
      const url = URL.createObjectURL(file);
      const video = document.createElement("video");
      
      video.preload = "metadata";
      video.src = url;
      video.onloadedmetadata = () => {
        if (video.duration && video.videoWidth && video.videoHeight) {
          resolve({
            duration: video.duration,
            width: video.videoWidth,
            height: video.videoHeight
          });} else {
            reject("Could not retrieve video duration.");
          }
          URL.revokeObjectURL(url); // Clean up object URL
        };
        
        video.onerror = (error) => {
          URL.revokeObjectURL(url); // Clean up object URL
          reject("Error loading video metadata: " + error);
        };
    } catch (error) {
      reject("Error getting video duration: " + error);
    }
  });
  useEffect(() => {
    const loadFFmpeg = async () => {
      if (!ffmpeg.loaded) {
        await ffmpeg.load();
      }
    };
    loadFFmpeg();
  }, []);

  const getCompressionSettings = (quality, originalBitrate, width, height) => {
    let adjustedBitrate;
    let newWidth = width, newHeight = height;
    switch (quality) {
      case "high":
        adjustedBitrate = Math.min(originalBitrate * 0.7,2000);
        newWidth = Math.min(Math.round(width * 0.7), 1920);
        newHeight = Math.min(Math.round(height * 0.7), 1080);
        break;
      case "medium":
        adjustedBitrate = Math.min(originalBitrate * 0.5,1000)
        newWidth = Math.min(Math.round(width * 0.5), 1280);
        newHeight = Math.min(Math.round(height * 0.5), 720);
        break;
      case "low":
        adjustedBitrate = Math.min(originalBitrate * 0.33,500)
        newWidth = Math.min(Math.round(width * 0.33), 640);
        newHeight = Math.min(Math.round(height * 0.33), 360);
        break;
      default:
        adjustedBitrate = originalBitrate * 0.3;
        newWidth = Math.max(Math.round(width * 0.7), 540);
        newHeight = Math.max(Math.round(height * 0.7), 300);
    }
  
    // Ensure width & height are even (needed for FFmpeg)
    newWidth = Math.floor(newWidth / 2) * 2;
    newHeight = Math.floor(newHeight / 2) * 2;
    return {
      bitrate: adjustedBitrate.toFixed(0) + "k",
      width: newWidth,
      height: newHeight,
    };
  };

  const handleCompressVideo = async () => {
    if (!videoSrc) return;
    setLoading(true);

    try {
      const inputFilename = "input.mp4";
      const outputFilename = "output.mp4";
      const { blob, file } = await getBlobObject(videoSrc);
      const metadata = await getVideoDuration(file);
      const duration=metadata.duration
      const originalBitrate = (blob.size * 8) / (duration * 1000); // Convert bytes to kbps
      const { bitrate, width, height } = getCompressionSettings(quality, originalBitrate, metadata.width, metadata.height);

      await ffmpeg.writeFile(inputFilename, await fetchFile(videoSrc));
  
      ffmpeg.on("progress", ({ progress }) => {
        setProgress(Math.floor(progress * 100));
      });
  
      await ffmpeg.exec([
        "-i", inputFilename,
        "-b:v", bitrate,
        "-vf", `scale=${width}:${height}`,
        "-preset", "ultrafast",
        outputFilename,
      ]);
      const data = await ffmpeg.readFile(outputFilename);

      const url = URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }));
      setCompressedVideo(url);
      setCompressedSize(data.buffer.byteLength);
    } catch (error) {
      console.error("Compression error:", error);
    }
    setLoading(false);
    setTimeout(() => setProgress(0), 1000);
  };

  const handleReset = () => {
    setCompressedVideo(null);
    setCompressedSize(null);
    setProgress(0);
  };

  const handleUpload = async() => {
      const { file } = await getBlobObject(compressedVideo);
      uploadImage(null,file,"file",file);
  };
  

  if (isUploading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2, alignItems: "center" }}>
      {imageSrc && (
        <>
          <img src={imageSrc} alt="Preview" style={{ width: "20vw" }} />
          <Typography variant="body2">Image size: {(mediaSize / (1024 * 1024)).toFixed(2)} MB</Typography>
        </>
      )}
      {videoSrc && (
        <>
          <ReactPlayer url={compressedVideo || videoSrc} controls width="20vw" />
          <Typography variant="body2" color="error">
            Video size: {(mediaSize / (1024 * 1024)).toFixed(2)} MB. Please reduce the size to be under 10 MB.
          </Typography>
          {compressedVideo && (
            <>
              <Typography variant="body2" color={compressedSize / (1024 * 1024) > 10 ? "error" : "success"}>
                Compressed video size: {(compressedSize / (1024 * 1024)).toFixed(2)} MB
              </Typography>
              <Button variant="outlined" onClick={handleReset}>
                Reset
              </Button>
              <Button variant="contained" onClick={handleUpload}>
                Upload
              </Button>
            </>
          )}
          {!compressedVideo && (
            <>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Typography variant="body2">Quality:</Typography>
                <Select value={quality} onChange={(e) => setQuality(e.target.value)}>
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="low">Low</MenuItem>
                </Select>
              </Box>
              <Button variant="contained" onClick={handleCompressVideo} disabled={loading}>
                {loading ? "Compressing..." : "Reduce Video Size"}
              </Button>
              {loading && (
                <Box sx={{ width: "100%", mt: 2 }}>
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

ImagePreviewAndCompress.propTypes = {
  imageSrc: PropTypes.string,
  videoSrc: PropTypes.string,
  mediaSize: PropTypes.number,
  isUploading: PropTypes.bool,
  uploadImage: PropTypes.func.isRequired,
};

export default ImagePreviewAndCompress;