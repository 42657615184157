export function shortId() {
    return "_" + Math.random().toString(36).substr(2, 9);
  }
  
  export function randomColor() {
    return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
  }
  
/**
 * Performs binary search on a sorted array of objects.
 * @param {Array} data - Array to search; @param {*} targetValue - Value to search for.  @param {Function} [comparator] - Optional comparison logic.
 * @returns {number} - Index of the matching object, or -1 if not found.
**/
export function searchIndex(data, targetValue, comparator) {
  let left = 0;
  let right = data.length - 1;
  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const comparison = comparator(data[mid], targetValue);
    if (comparison === 0) {
      return mid; 
    } else if (comparison < 0) {
      left = mid + 1; 
    } else {
      right = mid - 1; 
    }
  }

  return -1; 
}

export const updateUrlParam = (param, value) => {
  const newUrl = new URL(window.location.href);
  if (value !== null && value !== undefined) {
    newUrl.searchParams.set(param, value);
  } else {
    newUrl.searchParams.delete(param);
  }
  window.history.pushState({}, '', newUrl);
};

export const formatToIST = (dateValue) => new Date(dateValue).toString();

export const getKanbanHeaderData = (dataType, value) => {
  if ((value)&&['updatedat', 'createdat', 'datetime'].includes(dataType)) {
    return formatToIST(value);
  }
  return value?.[0]?.name || value?.name || value || "";
};


 
