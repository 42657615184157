import React from 'react'
import PropTypes from 'prop-types'

import {LaneFooter} from '../../styles/Base'

import {
  CollapseBtn,
  ExpandBtn,
} from '../../styles/Elements'

const LaneFooterComponent = ({onClick, collapsed}) => (
  <LaneFooter onClick={onClick}>
    {collapsed ? <ExpandBtn /> : <CollapseBtn />}
  </LaneFooter>
)

LaneFooterComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
}

export default LaneFooterComponent
