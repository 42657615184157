import React, { useState, useCallback, useEffect, useRef } from "react";
import { CompactSelection, DataEditor } from "@glideapps/glide-data-grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {deleteRows, updateColumnHeaders } from "../store/table/tableThunk";
import PropTypes from "prop-types";
import "./Glidedatagrid.css";
import "../../src/App.scss";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "./style.css";
import FieldPopupModal from "./fieldPopupModal/fieldPopupModal";
import { addRow,getDataExternalFunction,reorderFuncton,editCellsInBatch, addMultipleRow} from "./addRow";
import Headermenu from "./headerMenu";
import { useExtraCells } from "@glideapps/glide-data-grid-cells";
// import { getTableInfo } from "../store/table/tableSelector";
import SelectFilepopup from "./selectFilepopup";
import { headerIcons } from "./headerIcons";
import variables from "../assets/styling.scss";
import { customUseSelector } from "../store/customUseSelector";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import RowHistoryPopup from "../component/rowHistoryPopup/rowHistoryPopup";
// import { getRowHistory1 } from "../store/table/tableThunk";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import LinkAutoCompletePopup from "../component/linkAutoCompletePopup/linkAutoCompletePopup";
import RowPopup from "../component/table/rowPopup/rowPopup";
import { searchIndex, updateUrlParam } from "./utils";
import { getDbsOfOrg } from "../store/database/databaseThunk";

export default function MainTable(props) {
  const params = useParams();

  const querystring = new URLSearchParams(window.location.search);
  const rowId = querystring.get('row');

  const cellProps = useExtraCells();
  const dispatch = useDispatch();
  const allFieldsofTable = customUseSelector((state) => state.table.columns);//fields from redux
  const allRowsData = customUseSelector((state) => state.table.data || []); // data from redux
  const users = customUseSelector((state) => state.dataBase.currentOrgUsers || {});
  const [openAttachment, setOpenAttachment] = useState(null);
  const [menu, setMenu] = useState();
  const [directionAndId, setDirectionAndId] = useState({});
  const [showSearch, setShowSearch] = useState(false);
  const [showDeletedRows, setShowDeletedRows] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(false);
  const targetColumn = useRef(0); // 
  const readOnlyDataTypes = [ "autonumber", "createdat", "createdby", "rowid", "updatedby", "updatedat"];
  const [anchorEl, setAnchorEl] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [linkAutoComplete, setLinkAutoComplete] = useState(null);
  const [autonumber, setAutonumber] = useState(rowId || 0);
  const [editField, setEditField] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const hasExpanded = useRef(false);
  let eventDoubleClicked = null;

  const emptyselection = {
    columns: CompactSelection.empty(),
    rows: CompactSelection.empty(),
    current: undefined,
  };
  const [selection, setSelection] = useState(emptyselection);
  const [fieldsToShow, setFieldsToShow] = useState(allFieldsofTable || []);
  // const tableInfo = customUseSelector((state) => getTableInfo(state));
  // const tableId = tableInfo?.tableId;
    useEffect(() => {
      if(Object.keys(users).length === 0){
        dispatch(getDbsOfOrg(params.orgId));
      }
    }, []);
  
  
  const handleClickOnCell = useCallback((cell, event) => {
    if (!allRowsData) return;
    const [col, row] = cell;
    const dataRow = allRowsData?.[row] || allRowsData?.[row - 1];
    const d = dataRow?.[fieldsToShow?.[col]?.id];
    const index = cell?.[0];
    if (fieldsToShow?.[index]?.dataType === "attachment" && !fieldsToShow?.[index]?.metadata?.isLookup) {
      setOpenAttachment({
        cell,
        d,
        fieldId: fieldsToShow?.[col]?.id,
        // rowAutonumber: allRowsData[row][`fld${tableId?.substring(3)}autonumber`],
        rowAutonumber: dataRow[`autonumber`],
      });
    }
    eventDoubleClicked = event;
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.code === "KeyF") {
        setShowSearch(!showSearch);
        event.stopPropagation();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    var newcolumn = [];
    allFieldsofTable.forEach((column) => {
      if (column?.metadata?.hide !== true) {
        newcolumn.push(column);
      }
    });

    const targetIndex = newcolumn.findIndex((field) => {
      const dataType = field.dataType;

      return !readOnlyDataTypes.includes(dataType);
    });

    if (targetIndex !== -1) {
      targetColumn.current = targetIndex;
    }
    setFieldsToShow(newcolumn);
  }, [allFieldsofTable]);

  const addRows = () => {
    if (params?.templateId) return;
    addRow(dispatch);
  };

  const reorder = useCallback(
    (item, newIndex) => {
      if (params?.templateId) return;
      reorderFuncton(
        dispatch,
        item,
        newIndex,
        fieldsToShow,
        allFieldsofTable,
        params?.filterName,
        setFieldsToShow
      );
    },
    [fieldsToShow, allFieldsofTable]
  );

  const onCellsEdited=useCallback((list)=>{
    if(!list || list.length===0) return;
    editCellsInBatch(list, dispatch,fieldsToShow,params,allRowsData, users);
  },[fieldsToShow,allRowsData])
  

  const handleColumnResizeWithoutAPI = useCallback((_, newSize, colIndex) => {
    let newarrr = [...(fieldsToShow || allFieldsofTable)];
    let obj = Object.assign({}, newarrr[colIndex]);
    obj.width = newSize;
    newarrr[colIndex] = obj;
    setFieldsToShow(newarrr);
  });
  
  const handleColumnResize = (field, newSize) => {
    if (params?.templateId) return;
    dispatch(
      updateColumnHeaders({
        filterId: params?.filterName,
        dbId: params?.dbId,
        tableName: params?.tableName,
        columnId: field?.id,
        metaData: { width: newSize },
      })
    );
  };
  
  const handleDeleteRow = (selection) => {
    if (params?.templateId || selection?.current) return;
    const deletedRowIndices = [];
    for (const element of selection.rows.items) {
      const [start, end] = element;
      for (let i = start; i < end; i++) {
        // deletedRowIndices.push(allRowsData[i][`fld${tableId.substring(3)}autonumber`] );
        deletedRowIndices.push(allRowsData[i][`autonumber`] );
      }
    }
    if (deletedRowIndices.length > 0)  dispatch(deleteRows({ deletedRowIndices, dataa: allRowsData, indicesRange : selection.rows.items}));
    setSelection(emptyselection);
  };

  const onHeaderMenuClick = useCallback((col, bounds) => {
    if (params?.templateId) return;
    setMenu({ col, bounds });
  }, []);

  const getData = useCallback(
    (cell) =>
      getDataExternalFunction(cell,allRowsData,fieldsToShow,readOnlyDataTypes, users),
    [allRowsData, fieldsToShow, users]
  );

  const handlegridselection = (event) => {setSelection(event);};

  const handleRightClickOnHeader = useCallback((col, event) => {
    if (params?.templateId) return;
    event.preventDefault();
    setMenu({ col, bounds: event.bounds });
  });

  const getRowThemeOverride = (row) => {
    if (row != hoveredRow || open || menu || openAttachment) return;
    return {
      bgCell: variables.rowHoverColor,
      bgCellMedium: variables.codeblockbgcolor,
    };
  };

  const getHoveredItemsInfo = (event) => {setHoveredRow(event?.location[1]); };
  const closePopper = () => {
    setAnchorEl(null);
    setEditField(null);
  };

  const openPopper = (event) => {
    if(params?.templateId) return ;
    if(event.clientX !== undefined){
      setAnchorEl({
        top : event.clientY, 
        left : event.clientX
      })
    }else{
      setAnchorEl({
        top : event.y, 
        left : event.x
      })
    }
  }

  const handleShowHistory = (index) => {
    setAutonumber(allRowsData[index]['autonumber']);
    setShowHistory(true);

  }

  const handleExpandRow = (index) => {
    const rowData = allRowsData[index];
    setSelectedRowData(rowData)
    updateUrlParam('row',rowData.autonumber)
  }

  useEffect(()=>{
    if(allRowsData.length == 0 && hasExpanded.current && !rowId) return ;
      const index = searchIndex(allRowsData,rowId,(data,autonumber)=> data.autonumber - autonumber);
      if(index == -1) return;
      handleExpandRow(index);
      hasExpanded.current=true;
  },[rowId,allRowsData])

  const handleShowDeletedRows = () => {
    setShowDeletedRows((old)=>old===true?false:true)
  }
  
  
  const handleClose = ()=>{
    setShowDeletedRows(false);
    setShowHistory(false);
    setAutonumber(0);
    setLinkAutoComplete(null)
    setSelectedRowData(null)
    updateUrlParam('row');
  }
  
  const onCellActivated = (cell) => {
    const [col, row] = cell;
    if(!eventDoubleClicked || fieldsToShow?.[col]?.dataType !== "link" || fieldsToShow?.[col]?.metadata?.isLookup) return;
    setLinkAutoComplete({
      cell,
      fieldId: fieldsToShow?.[col]?.id,
      referencedFieldId: fieldsToShow?.[col]?.metadata?.foreignKey?.fieldId,
      rowAutonumber: allRowsData[row][`autonumber`],
      referencedTableName: fieldsToShow?.[col]?.metadata?.foreignKey?.tableId,
      anchorPosition: eventDoubleClicked.bounds
    })
    eventDoubleClicked.preventDefault();
  }
  const onPaste = (target, values)=>{
    const fields = fieldsToShow.slice(target[0], target[0]+values[0].length);
    const rows = [];
    for(let i = allRowsData.length-target[1];i < values.length;i++){
        const row = {};
        for(let field in fields){
          const fieldValue = values?.[i]?.[field];
          if(fields[field].dataType === 'multipleselect'){
            row[fields[field].title] = (typeof fieldValue === 'string' && fieldValue.length > 0) ? fieldValue.split(',') : null;
          }else{
            row[fields[field].title] = fieldValue;
          }
        }
        rows.push(row);
    }
    if(rows.length > 0) addMultipleRow(dispatch, rows);
    return true;
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {
        selection?.rows?.items.length === 1 && selection.rows.items[0][0]+1 === selection.rows.items[0][1] && (
          <button className="fontsize revision-history-button" onClick = {()=>handleShowHistory(selection.rows.items[0][0])}>
            <div className="revision-history">Revision History</div>
            <div><HistoryIcon /></div>
          </button>
      )}
      {selection?.rows?.items.length === 1 && selection.rows.items[0][0]+1 === selection.rows.items[0][1] && (
            <button className="fontsize expand-row-button" onClick={() => handleExpandRow(selection.rows.items[0][0])}>
              <div className="expand-row">Expand row </div>
              <div><OpenInFullIcon  /></div>
            </button>
      )}
      {selection?.rows?.items?.length > 0 && (
        <button className="fontsize deleterowbutton" onClick={() => handleDeleteRow(selection)}>
          <div className="deleterows">Delete Rows</div>
          <div><DeleteOutlineIcon className="deletecolor" /></div>
        </button>
      )}
      {
        selection?.rows?.items.length === 0 && (
          <button className="fontsize deleterowbutton" onClick = {()=>handleShowDeletedRows()}>
            <div className="revision-history">Deleted Rows</div>
            <div><RestoreFromTrashIcon /></div>
          </button>
      )}
      <div className="table-container" style={{ height: props?.height || `74vh` }}>
        <DataEditor
          {...cellProps}
          width={props?.width || window.screen.width}
          fillHandle={true}
          getCellContent={getData}
          onRowAppended={addRows}
          columns={fieldsToShow}
          rows={allRowsData.length}
          gridSelection={selection}
          rowMarkers="both"
          rowSelectionMode="multi"
          onItemHovered={getHoveredItemsInfo}
          onGridSelectionChange={handlegridselection}
          // onCellEdited={onCellEdited}
          onCellsEdited={onCellsEdited}
          // validateCell={validateCell}
          onHeaderContextMenu={handleRightClickOnHeader}
          getCellsForSelection={true}
          showSearch={showSearch}
          getRowThemeOverride={getRowThemeOverride}
          onSearchClose={() => setShowSearch(false)}
          onCellClicked={handleClickOnCell}
          onColumnResize={handleColumnResizeWithoutAPI}
          onColumnResizeEnd={handleColumnResize}
          onHeaderMenuClick={onHeaderMenuClick}
          headerIcons={headerIcons}
          showMinimap={props?.minimap}
          onColumnMoved={reorder}
          onPaste={onPaste} 
          rightElement={
            <IconButton aria-label="add" size="medium" variant="contained" aria-describedby={id} type="button" onClick={openPopper}>
                <AddIcon fontSize="medium" />
            </IconButton>
          }
          rightElementProps={{sticky:false, fill:true}}
          trailingRowOptions={{ sticky: true,tint: true,hint: "New row...",targetColumn: targetColumn.current,}}
          onCellActivated = {onCellActivated}
        />
      </div>
          
      {selectedRowData && (
        <RowPopup
        rowData={selectedRowData}
        closePopup={handleClose}
        />
      )}
      
      {open && (
        <FieldPopupModal
          title= {editField ? 'edit column' : "create column"}
          label="Column Name"
          tableId={params?.tableName}
          open={open}
          setDirectionAndId={setDirectionAndId}
          directionAndId={directionAndId}
          anchorEl = {anchorEl}
          closePopper = {closePopper}
          id={id}
          editFieldId = {editField}
        />
      )}
      {menu && (
        <Headermenu
          menu={menu}
          setMenu={setMenu}
          openPopper = {openPopper}
          setDirectionAndId={setDirectionAndId}
          fields={fieldsToShow}
          setEditField = {setEditField}
        />
      )}
      {openAttachment && (
        <SelectFilepopup
          title="uplaodfile"
          label="UploadFileIcon"
          attachment={openAttachment}
          open={openAttachment ? true : false}
          setOpen={setOpenAttachment}
        />
      )}
      {
        showHistory && (
          <RowHistoryPopup
              open = {showHistory}
              handleClose = {handleClose}
              autonumber = {autonumber}
              fields = {allFieldsofTable.filter(field=>!readOnlyDataTypes.includes(field.id))}
              rowIndex = {selection.rows.items[0][0]}
          />
        )
      }
      {
        linkAutoComplete && (
          <LinkAutoCompletePopup
            id={id}
            open={linkAutoComplete ? true : false}
            linkAutoComplete={linkAutoComplete}
            handleClose={handleClose}
          />
        )
      }
      {
        showDeletedRows && (
          <RowHistoryPopup
              open = {showDeletedRows}
              handleClose = {handleClose}
          />
        )
      }
    </>
  );
}
MainTable.propTypes = {
  minimap: PropTypes.any,
  height: PropTypes.any,
  width: PropTypes.any,
};